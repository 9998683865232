import { formatoFecha } from '@/utils/fechas'
import { tiposEstados } from '@/utils/entregaLuminarias/entregaLuminariasUtils'
import {
  estiloSubtitulos, estiloTitulos, estiloRows, estiloColumns,
} from '@/utils/reportes/estilosReportes/estilosReportesGenerales'

export const tituloPrincipal = {
  titulo: 'ALUMBRADO PUBLICO DE MIXCO, S.A',
  style: estiloTitulos,
}
export const tituloSecundario = {
  titulo: 'LUMINARIAS ENTREGADAS',
  style: estiloTitulos,
}
export const subtitulo = {
  subtitulo: 'REPORTE GENERAL DE LUMINARIAS ENTREGADAS',
  style: estiloSubtitulos,
}
export const tituloDescarga = 'REPORTE GENERAL DE LUMINARIAS ENTREGADAS'
export function getColumns() {
  return [
    {
      label: 'Ticket No.',
      width: 10,
      style: estiloColumns,
    },
    {
      label: 'Encargado',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'Persona que Recibirá',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'Fecha de Entrega',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'Estado',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'Fecha Creación',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'ID de Luminaria Entrega',
      width: 30,
      style: estiloColumns,
    },
  ]
}
export function getRows(rows) {
  const listado = []

  rows.forEach(row => {
    const fechaFormat = formatoFecha(row.fechaCreacion)
    const tiposEstado = tiposEstados[row.estado]
    const item = {
      ticket: {
        value: row.ticket,
        type: 'number',
        style: estiloRows,
      },
      encargado: {
        value: row.usuarioEncargado.nombre,
        type: 'string',
        style: estiloRows,
      },
      receptor: {
        value: row.nombreReceptor,
        type: 'string',
        style: estiloRows,
      },
      fechaEntrega: {
        value: row.fechaEntrega,
        type: 'string',
        style: estiloRows,
      },
      estado: {
        value: tiposEstado,
        type: 'string',
        style: estiloRows,
      },
      fechaCreacion: {
        value: fechaFormat,
        type: 'string',
        style: estiloRows,
      },
      id: {
        value: row.id ? row.id : '',
        type: 'string',
        style: estiloRows,
      },
    }
    listado.push(item)
  })

  return listado
}
