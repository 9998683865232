<template>
  <div>
    <b-overlay
      :show="loading"
    >
      <template #overlay>
        <div
          class="text-center"
        >
          <feather-icon
            icon="LoaderIcon"
            size="40"
            variant="primary"
            class="rotate"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>

      <b-row>
        <b-col cols="3" />
        <b-col
          v-if="!isMobile"
          cols="3"
        >
          <b-form-group>
            <label>Estado:</label>
            <v-select
              v-model="etapa"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Inicio:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="example-input"
                v-model="fechaInicio"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaInicio"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Fin:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="fecha-fin"
                v-model="fechaFin"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaFin"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          style="margin-top: 23px; margin-left: -10px"
          cols="1"
        >
          <table>
            <tr>
              <td>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary
                  title="Buscar"
                  variant="primary"
                  class="btn-icon"
                  @click="loadReport()"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </td>
              <td v-if="usuario.company.enabledReport">
                <reporte-general
                  :rows="rowsReporte"
                  :columns="columnsReporte"
                  :titulo-descarga="tituloDescarga"
                  :subtitulo="subtitulo"
                  :titulo-secundario="tituloSecundario"
                  :titulo-principal="tituloPrincipal"
                />
              </td>
              <td v-if="usuario.company.enabledReport">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary
                  title="Descargar Documento PDF EEGSA"
                  variant="primary"
                  class="btn-icon"
                  @click="descargarReporteEegsaMensual"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-button>
              </td>
              <td>
                <reporte-excel-eegsa
                  :fecha-inicio="fechaInicio"
                  :fecha-fin="fechaFin"
                />
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar',
            }"
            style-class="vgt-table condensed"
            @on-row-dblclick="showDetalle"
          >
            <!-- detalles -->
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'fechaCreacion'">
                {{ formatFecha(props.row.fechaCreacion) }}
              </span>
              <span v-else-if="props.column.field === 'estado'">
                {{ getEstados(props.row.estado) }}
              </span>
              <span v-else-if="props.column.field === 'opciones'">
                <!-- EL REPORTE DE EEGSA ESTA PENDIENTE-->
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="props.row.estado === 'PENDIENTE'"
                    @click="generarDocumento(props.row)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Generar Reporte Municipalidad</span>
                  </b-dropdown-item>
                  <!--b-dropdown-item
                    @click="generarDocumentoEegsa(props.row)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Generar Reporte EEGSA</span>
                  </b-dropdown-item-->
                </b-dropdown>
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-overlay>
    <div>
      <b-modal
        id="modal-1"
        v-model="verModalDetalle"
        title="Detalle de Entrega de Luminaria"
        ok-only
        hide-footer
        ok-title="Accept"
        no-close-on-backdrop
      >
        <modal-detalle-seguimiento-tickets-entrega
          :ticket="ticket"
          @cerrar="verModalDetalle = false"
        />
      </b-modal>
    </div>
    <div>
      <b-toast
        id="notification-descarga"
        no-auto-hide
        no-close-button
      >
        <template #toast-title>
          <div class="d-flex flex-grow-1 align-items-center mr-1">
            <b-img
              :src="require('@/assets/images/logo/apmixlogo.png')"
              class="mr-1"
              height="30"
              width="75"
              alt="Toast image"
            />
            <strong class="mr-auto">GENERANDO PDF...</strong>
          </div>
        </template>
        <span>Generando reporte, esto puede tomar unos minutos... </span>
        <div>
          <b-row>
            <b-col cols="11">
              <b-progress
                key="progressBarDownload"
                animated
                max="100"
                :value="porcentajeDescarga"
                variant="success"
                style="margin-top: 5px"
                class="'progress-bar-success"
              />
            </b-col>
            <b-col cols="1">
              <b-card-text style="margin-left: -18px">
                {{ porcentajeDescarga+'%' }}
              </b-card-text>
            </b-col>
          </b-row>
        </div>
      </b-toast>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BCardText,
  BCol, BDropdown, BDropdownItem,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BToast,
  BImg,
  BProgress,
  VBTooltip,
} from 'bootstrap-vue'
import { isMobile } from '@/utils/funciones'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { tiposEstados } from '@/utils/entregaLuminarias/entregaLuminariasUtils'
import {
  calcularFecha, formatDate, obtenerFechaInicioFin, sumarDiasFecha,
} from '@/utils/fechas'
import ModalDetalleSeguimientoTicketsEntrega from '@/components/entregaLuminarias/modalDetalleSeguimientoTicketsEntrega.vue'
import {
  generarReporte,
  findTicketEntregaLuminaria,
  generarReporteEegsa,
  generarResumenEegsa,
  generarReporteEegsaMensual,
  postReporteEegsa,
  generarResumenEegsaMensual,
} from '@/utils/entregaLuminarias/ticketEntregaLuminariasUtils'
import { getMigracion } from '@/utils/migracionLuminarias'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'
import {
  getColumns, getRows, tituloPrincipal, tituloSecundario, subtitulo, tituloDescarga,
} from '@/utils/reportes/entregaLuminarias/luminariasEntrega'
import { notificacionError, notificacionInformativa } from '@/utils/mensajes'
import { getPoste } from '@/utils/postes'
import { PDFDocument } from 'pdf-lib'
import ReporteExcelEegsa from '@/components/reportes/reportesEegsa/reporteExcelEegsa.vue'

export default {
  components: {
    ReporteExcelEegsa,
    ReporteGeneral,
    ModalDetalleSeguimientoTicketsEntrega,
    BRow,
    BCol,
    BToast,
    BCardText,
    BImg,
    vSelect,
    BButton,
    BOverlay,
    BDropdown,
    BProgress,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
    BFormDatepicker,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      porcentajeDescarga: 0,
      cargaVisible: true,
      loading: false,
      verModalDetalle: false,
      verModalAsignar: false,
      fechaInicio: '',
      fechaFin: '',
      etapa: '',
      seleccionadas: [],
      ticket: null,
      pageLength: 15,
      rows: [],
      option: [
        { title: 'Todos', value: 'TODOS' },
        { title: 'Pendientes', value: 'PENDIENTE' },
        { title: 'Finalizadas', value: 'FINALIZADA' },
      ],
      columns: [
        {
          label: 'Ticket N.',
          field: 'ticket',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
          type: 'number',
        },
        {
          label: 'Persona Recibe',
          field: 'nombreReceptor',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha de Entrega',
          field: 'fechaEntrega',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Técnico Encargado',
          field: 'usuarioEncargado.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Creación',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Opciones',
          field: 'opciones',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      columnsReporte: [],
      rowsReporte: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
    isMobile() {
      return isMobile()
    },
  },
  async created() {
    this.rows = []
    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    await this.loadReport()
  },
  methods: {
    async reducirImagen(url, maxWidth = 250, maxHeight = 250) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.crossOrigin = 'anonymous' // Necesario para evitar problemas CORS
        img.src = url

        img.onload = () => {
          const canvas = document.createElement('canvas')
          let { width, height } = img

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width
              width = maxWidth
            }
          } else if (height > maxHeight) {
            width *= maxHeight / height
            height = maxHeight
          }

          canvas.width = width
          canvas.height = height

          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL('image/jpeg', 0.5)) // Ajustar la calidad a 50%
        }

        img.onerror = reject
      })
    },
    async descargarReporteEegsaMensual() {
      try {
        this.loading = true
        this.porcentajeDescarga = 0
        this.$bvToast.show('notification-descarga')

        const data = {
          companyId: this.usuario.company.id,
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        }
        const datosMigraciones = await postReporteEegsa(data)

        const primeros5 = datosMigraciones.items.slice(0, 5)
        const restantes = datosMigraciones.items.slice(5)

        // eslint-disable-next-line no-restricted-syntax
        for (const item of primeros5) {
          // eslint-disable-next-line no-await-in-loop
          item.fotoAntes = await this.reducirImagen(item.fotoAntes)
          // eslint-disable-next-line no-await-in-loop
          item.fotoDespues = await this.reducirImagen(item.fotoDespues)
        }

        const batchSize = 10
        const totalPagesRestantes = Math.ceil(restantes.length / batchSize) + 1

        const resumenPdf = await generarResumenEegsaMensual(datosMigraciones, { items: primeros5 }, 1, 1, totalPagesRestantes, data)

        const pdfChunks = []
        let globalCount = 6
        let pageNumber = 2
        // Verificar si hay elementos restantes
        if (datosMigraciones.items.length > 5) {
          const totalPages = Math.ceil(restantes.length / batchSize) + 1
          globalCount = 6 // Comienza desde 6 porque ya se procesaron los primeros 5

          for (let i = 0; i < restantes.length; i += batchSize) {
            const migracionBatch = restantes.slice(i, i + batchSize)

            // eslint-disable-next-line no-restricted-syntax
            for (const item of migracionBatch) {
              // eslint-disable-next-line no-await-in-loop
              item.fotoAntes = await this.reducirImagen(item.fotoAntes)
              // eslint-disable-next-line no-await-in-loop
              item.fotoDespues = await this.reducirImagen(item.fotoDespues)
            }
            // eslint-disable-next-line no-await-in-loop
            const fileResumen = await generarReporteEegsaMensual(migracionBatch, globalCount, pageNumber, totalPages, data)
            if (fileResumen !== null) {
              const pdfData = fileResumen.Base64
              const pdfBytes = Uint8Array.from(atob(pdfData), c => c.charCodeAt(0))
              pdfChunks.push(pdfBytes)
            }
            // eslint-disable-next-line no-plusplus
            pageNumber++
            globalCount += batchSize
            // eslint-disable-next-line no-mixed-operators
            this.porcentajeDescarga = Math.round((i + batchSize) / restantes.length * 100)
          }

          if (pdfChunks.length > 0) {
            const mergedPdf = await PDFDocument.create()

            const resumenPdfBytes = Uint8Array.from(atob(resumenPdf.Base64), c => c.charCodeAt(0))
            const resumenPdfDoc = await PDFDocument.load(resumenPdfBytes)
            const resumenPages = await mergedPdf.copyPages(resumenPdfDoc, resumenPdfDoc.getPageIndices())
            resumenPages.forEach(page => mergedPdf.addPage(page))

            // Agregar PDFs detallados
            // eslint-disable-next-line no-restricted-syntax
            for (const pdfBytes of pdfChunks) {
              // eslint-disable-next-line no-await-in-loop
              const pdf = await PDFDocument.load(pdfBytes)
              // eslint-disable-next-line no-await-in-loop
              const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
              copiedPages.forEach(page => mergedPdf.addPage(page))
            }

            const mergedPdfBytes = await mergedPdf.save()
            const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' })
            const nombreArchivo = `Documento reporte EEGSA ${formatDate(new Date(data.fechaInicio), '/')} - ${formatDate(new Date(data.fechaFin), '/')}.pdf`
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = nombreArchivo
            link.click()
          }
        } else {
          const mergedPdf = await PDFDocument.create()

          const resumenPdfBytes = Uint8Array.from(atob(resumenPdf.Base64), c => c.charCodeAt(0))
          const resumenPdfDoc = await PDFDocument.load(resumenPdfBytes)
          const resumenPages = await mergedPdf.copyPages(resumenPdfDoc, resumenPdfDoc.getPageIndices())
          resumenPages.forEach(page => mergedPdf.addPage(page))

          const mergedPdfBytes = await mergedPdf.save()
          const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' })
          const nombreArchivo = `Documento reporte EEGSA ${formatDate(new Date(data.fechaInicio), '/')} - ${formatDate(new Date(data.fechaFin), '/')}.pdf`
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = nombreArchivo
          link.click()
        }
        this.$bvToast.hide('notification-descarga')
        this.porcentajeDescarga = 100
        notificacionInformativa('Documento generado!', 'Documento generado correctamente!')
      } catch (error) {
        this.$bvToast.hide('notification-descarga')
        notificacionError('Documento no generado', 'Inconvenientes al intentar generar el documento. ):')
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async loadReport() {
      this.loading = true
      try {
        const fechaInicio = new Date(this.fechaInicio)
        const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)
        const filter = {
          where: {
            and: [
              { companyId: this.usuario.idCompany },
              { fechaCreacion: { gte: fechaInicio.toISOString() } },
              { fechaCreacion: { lte: fechaFin.toISOString() } },
            ],
          },
          order: ['fechaCreacion DESC'],
        }

        if (this.etapa && this.etapa.value !== '') {
          filter.where.and.push({ estado: this.etapa.value })
        }
        this.rows = await findTicketEntregaLuminaria(filter)
        this.rowsReporte = getRows([...this.rows])
        this.columnsReporte = getColumns()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async generarDocumento(row) {
      this.loading = true
      try {
        const ticket = row
        const migraciones = []
        const postes = []
        const batchSize = 5
        let totalPages = 0

        const totalMigraciones = ticket.idsRelacionados.length
        totalPages = Math.ceil(totalMigraciones / batchSize)
        let pageNumber = 1
        // eslint-disable-next-line no-restricted-syntax
        for (let i = 0; i < totalMigraciones; i += batchSize) {
          const migracionBatch = ticket.idsRelacionados.slice(i, i + batchSize)
          const migracionPromises = migracionBatch.map(migracionId => getMigracion(migracionId))
          // eslint-disable-next-line no-await-in-loop
          const migracionesBatch = await Promise.all(migracionPromises)
          migraciones.push(...migracionesBatch)

          // Obtén postes para cada migración en el lote
          // eslint-disable-next-line no-restricted-syntax
          for (const migracion of migracionesBatch) {
            // eslint-disable-next-line no-await-in-loop
            const poste = await getPoste(migracion.idPuntoIluminacion)
            if (poste) {
              postes.push(poste)
            }
          }
          const numPagesInBatch = Math.ceil(migracionesBatch.length / batchSize)
          // eslint-disable-next-line no-await-in-loop
          const fileResumen = await generarReporte(migraciones, postes, ticket, pageNumber, totalPages)
          if (fileResumen !== null) {
            const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
            const downloadLink = document.createElement('a')
            const fileName = `Entrega de Luminarias Municipalidad no. ${ticket.ticket}.pdf`
            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
          }
          pageNumber += numPagesInBatch

          migraciones.length = 0
          postes.length = 0
        }
        notificacionInformativa('Documento Descargado', 'Reporte de entrega de luminarias')
      } catch (error) {
        notificacionError('Documento no descargado', 'Inconvenientes al intentar descargar el documento')
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async generarDocumentoEegsa(row) {
      try {
        this.loading = true
        const ticket = row
        const batchSize = 7
        const totalMigraciones = ticket.idsRelacionados.length
        const migraciones = []

        const migracionPromises = ticket.idsRelacionados.map(migracionId => getMigracion(migracionId))
        const allMigraciones = await Promise.all(migracionPromises)
        migraciones.push(...allMigraciones)

        const resumenPdf = await generarResumenEegsa(migraciones)

        const pdfChunks = []
        let pageNumber = 1
        for (let i = 0; i < totalMigraciones; i += batchSize) {
          const migracionBatch = migraciones.slice(i, i + batchSize)
          // eslint-disable-next-line no-await-in-loop
          const fileResumen = await generarReporteEegsa(migracionBatch, ticket, pageNumber, Math.ceil(totalMigraciones / batchSize))
          if (fileResumen !== null) {
            const pdfData = fileResumen.Base64
            const pdfBytes = Uint8Array.from(atob(pdfData), c => c.charCodeAt(0))
            pdfChunks.push(pdfBytes)
          }
          pageNumber += 1
        }
        if (pdfChunks.length > 0) {
          const mergedPdf = await PDFDocument.create()

          // Agregar resumen PDF
          const resumenPdfBytes = Uint8Array.from(atob(resumenPdf.Base64), c => c.charCodeAt(0))
          const resumenPdfDoc = await PDFDocument.load(resumenPdfBytes)
          const resumenPages = await mergedPdf.copyPages(resumenPdfDoc, resumenPdfDoc.getPageIndices())
          resumenPages.forEach(page => mergedPdf.addPage(page))

          // Agregar PDFs detallados
          // eslint-disable-next-line no-restricted-syntax
          for (const pdfBytes of pdfChunks) {
            // eslint-disable-next-line no-await-in-loop
            const pdf = await PDFDocument.load(pdfBytes)
            // eslint-disable-next-line no-await-in-loop
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
            copiedPages.forEach(page => mergedPdf.addPage(page))
          }
          const mergedPdfBytes = await mergedPdf.save()
          const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Entrega de Luminaria Eegsa no. ${ticket.ticket}.pdf`
          link.click()
        }
        notificacionInformativa('Documento Descargado', 'Reporte de entrega de luminarias')
      } catch (error) {
        notificacionError('Documento no descargado', 'Inconvenientes al intentar descargar el documento')
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async showDetalle(params) {
      const ticket = params.row
      if (ticket) {
        this.ticket = ticket
        this.verModalDetalle = true
      }
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
    getEstados(row) {
      return tiposEstados[row]
    },
  },
}
</script>
<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
